<template>
    <div v-if="id">
        <add-new :id="id"/>
    </div>
</template>
<script>
import AddNew from "./AddNew.vue";
import { useRoute } from "vue-router";

export default {
    name: 'EditNew',
    components: { AddNew },
    data() {
        return {
            id: null
        }
    },
    created() {
        const { params } = useRoute()
        if (params?.id) {
            this.id = params.id
        }
    }
}
</script>
<style scoped>

</style>